import request from "@/api/service";

const api = {
   getAppletIndex: '/hadoop/member/getAppletIndex', // 小程序基础指标
   orderStatistics: '/hadoop/event/orderStatistics', // 小程序7日客单价变化
   getUvPv: '/system/loginLog/getUvPv', // 小程序UV 小程序PV
   getGMV: '/order/getGMV', //小程序GMV
   getGMVDay: '/order/getGMVDay', //小程序GMV近30天变化趋势
};

export function getAppletIndex(data) {
   return request({
      url: api.getAppletIndex,
      method: 'POST',
      data
   })
}

export function orderStatistics(params) {
   return request({
      url: api.orderStatistics,
      method: 'get',
      params
   })
}

export function getUvPv(data) {
   return request({
      url: api.getUvPv,
      method: 'POST',
      data
   })
}

export function getGMV(data) {
   return request({
      url: api.getGMV,
      method: 'POST',
      data
   })
}

export function getGMVDay(data) {
   return request({
      url: api.getGMVDay,
      method: 'POST',
      data
   })
}
