<!-- 集团管理 首页 -->
<template>
  <section class="cont group_index">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="search-box">
        <el-row class="search-row" style="margin-bottom:0">
          <el-row class="search-item">
            <el-radio-group v-model="checkboxGroup" @change="radioChange">
              <el-radio-button
                v-for="(state, idx) in statisticsStateList"
                :key="idx"
                :label="state.value"
                :value="state.value"
              > {{state.label}}</el-radio-button>
            </el-radio-group>
          </el-row>
          <el-row class="search-item">
            <el-date-picker
              v-if="checkboxGroup === 'customDate'"
              ref="datePicker"
              value-format="yyyy-MM-dd"
              class="date-ipt"
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-row>
          <el-button v-if="checkboxGroup === 'customDate'" type="primary" class="m-left-auto" @click="getAppletIndex();getData()">搜索</el-button>
        </el-row>
      </el-row>

      <el-row class="card-box m-top-20" type="flex">
        <div class="card-box-item">
          <p class="item-tit">小程序UV</p>
          <p class="item-date">{{dateSplice}}</p>
          <p class="item-text">{{ appletIndex.uv || 0 }}人</p>
        </div>
        <div class="card-box-item" style="margin: 0 100px">
          <p class="item-tit">小程序PV</p>
          <p class="item-date">{{dateSplice}}</p>
          <p class="item-text">{{ appletIndex.pv || 0 }}次</p>
        </div>
        <div class="card-box-item">
          <div>
            <p class="item-tit">小程序新用户数</p>
            <p class="item-date">{{dateSplice}}</p>
            <p class="item-text">{{ appletIndex.newUserCount || 0 }}人</p>
          </div>
        </div>
        <div class="card-box-item" style="margin-left:100px">
          <div>
            <p class="item-tit">小程序活跃用户数</p>
            <p class="item-date">{{dateSplice}}</p>
            <p class="item-text">{{ appletIndex.activeUserCount || 0 }}人</p>
          </div>
        </div>
      </el-row>

      <el-row class="card-box m-top-60" type="flex">
        <div class="card-box-item">
          <p class="item-tit">小程序GMV</p>
           <p class="item-date">{{dateSplice}}</p>
          <p class="item-text">{{ appletIndex.gmv || 0 }}元</p>
        </div>
        <div class="card-box-item" style="margin: 0 100px">
          <p class="item-tit">小程序订单量</p>
          <p class="item-date">{{dateSplice}}</p>
          <p class="item-text">{{ appletIndex.payCount || 0 }}单</p>
        </div>
        <div class="card-box-item">
          <div>
            <p class="item-tit">小程序下单人数</p>
            <p class="item-date">{{dateSplice}}</p>
            <p class="item-text">{{ appletIndex.userCount || 0 }}人</p>
          </div>
        </div>
         <div class="card-box-item" style="margin-left:100px">
          <div>
            <p class="item-tit">小程序客单价</p>
            <p class="item-date">{{dateSplice}}</p>
            <p class="item-text">{{ appletIndex.avgPrice || 0 }}元</p>
          </div>
        </div>
      </el-row>
    </el-row>

     <div class="m-top-20 chart-box">
      <el-col class="content-box chart-item">
        <p class="m-bottom-10">小程序7日下单数量趋势</p>
        <span class="m-left-10">{{applet7Date[0] + '~' + applet7Date[1]}}</span>
        <CurveChart class="myChart" :xAxis="OrderTrendDate" name="下单数量" :series="OrderTrendData" :chartId="'orderTrend'" ref="orderTrend"/>
      </el-col>
      <el-col class="content-box chart-item">
        <p class="m-bottom-10">小程序7日客单价变化</p>
        <span class="m-left-10">{{applet7Date[0] + '~' + applet7Date[1]}}</span>
        <CurveChart class="myChart" :xAxis="priceDate" name="客单价" :series="priceData" :chartId="'priceChange'" ref="priceChange"/>
      </el-col>
    </div>

     <div class="m-top-20 chart-box">
      <el-col class="content-box chart-item-bg">
        <p class="m-bottom-10">小程序GMV近30天变化趋势</p>
        <span class="m-left-10">{{gmv30Date[0] + '~' + gmv30Date[1]}}</span>
        <CurveChart class="myChart" :xAxis="gmvDate" name="GMV" :series="gmvData" :chartId="'GMVChangeTrend'" ref="GMVChangeTrend"/>
      </el-col>
    </div>
  </section>
</template>

<script>
import { dateFactory } from "@/common/js/common";
import { getAppletIndex, orderStatistics, getUvPv, getGMV, getGMVDay } from "@/api/business/channelKanban/basicIndicators.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      crumbs: new Map([['业务中台'], ['渠道看板'], ['小程序基础指标']]),
      checkboxGroup: "yesterday",
      statisticsStateList: [
        { label: "昨日", value:'yesterday' },
        { label: "7日", value: 'week' },
        { label: "30日", value: 'month' },
        { label: "选择日期", value: 'customDate' },
      ],
      dateRange: [],
      StatisticsDate: [], // 展示日期
      gmv30Date: [dateFactory.getDistanceToday(-30, false), dateFactory.getDistanceToday(0, false)],
      applet7Date: [dateFactory.getDistanceToday(-7, false), dateFactory.getDistanceToday(0, false)],
      appletIndex: {},
      priceDate: [], // 小程序7日客单价变化日期
      priceData: [], // 小程序7日客单价变化数据
      OrderTrendDate: [], // 小程序7日下单数量趋势日期
      OrderTrendData: [], // 小程序7日下单数量趋势数据
      gmvDate: [], // GMV近30天日期
      gmvData: [], // GMV近30天数据
    };
  },
  mounted() {
    this.getWeekList = dateFactory.getWeekList()
    this.radioChange()
    this.getOrderTrend()
    this.getPriceChange()
    this.getGMVChangeTrend()
  },
  methods: {
    // 获取小程序基础指标数据
    getAppletIndex() {
     let params = {
      hotelId: this.hotelInfo.id,
     }
     if (!this.dateRange[1]) {
        params.date	 = this.dateRange[0]
      } else {
        params.startDate = this.dateRange[0]
        params.endDate = this.dateRange[1]
      }
     getAppletIndex(params).then(res => {
      this.appletIndex = {...res.records}
      this.StatisticsDate = this.dateRange
     })
    },

    getData() {
      let params = {
        hotelId: this.hotelInfo.id,
        orderSource: 'WECHAT_APPLET',
        beginTime: this.dateRange[0],
        endTime: this.dateRange[1] ? this.dateRange[1] : this.dateRange[0]
     }
     getUvPv(params).then(res => {
      this.appletIndex.uv = res.records.userCount
      this.appletIndex.pv = res.records.visits
     })
     getGMV(params).then(res => {
      this.appletIndex.gmv = res.records.totalMoney
     })
    },

    // 获取小程序7日下单数量趋势
     getOrderTrend() {
         this.$refs.orderTrend.drawChart()
     },

    //  小程序7日客单价变化
    getPriceChange() {
      let params = {
        hotelId: this.hotelInfo.id,
        beginTime: this.applet7Date[0],
        endTime: this.applet7Date[1]
      }
      orderStatistics(params).then(res => {
        if (res.success) {
          for (let i in res.records) {
            this.priceDate[i] = this.OrderTrendDate[i] = res.records[i].date.slice(5)
            this.priceData[i] = res.records[i].totalMoney &&  res.records[i].saleCount ? res.records[i].totalMoney/res.records[i].saleCount : 0
          
            this.OrderTrendData[i] = res.records[i].saleCount
          }
        }
        this.$refs.priceChange.drawChart()
        this.getOrderTrend()
      })
    },

    // 小程序GMV近30天变化趋势
    getGMVChangeTrend() {
      let params = {
        hotelId: this.hotelInfo.id,
        orderSource: 'WECHAT_APPLET',
        beginTime: dateFactory.getDistanceToday(0, false),
        endTime: dateFactory.getDistanceToday(0, false)
     }
      getGMVDay(params).then(res => {
        if (res.success) {
          this.gmv30Date = [res.records[res.records.length - 1].day, res.records[0].day]
          let length = 0
          for (let i in res.records) {
            length = res.records.length - 1 - i
            this.gmvDate[i] = res.records[length].day.slice(5)
            this.gmvData[i] = res.records[length].totalMoney
          }
        }
        this.$refs.GMVChangeTrend.drawChart()
      })
    },

    // 单选框事件
    radioChange() {
      switch (this.checkboxGroup) {
        case "yesterday":
          this.dateRange = [dateFactory.getDistanceToday(-1,false)];
          break;
        case "week":
          this.dateRange = [dateFactory.getDistanceToday(-7, false), dateFactory.getDistanceToday(0, false)];
          break;
        case "month":
          this.dateRange = this.getMonth();
          break;
        default: this.dateRange = []; return
      }
      this.getData()
      this.getAppletIndex()
    },
    //获取本月开始日期+结束日期
    getMonth(){
      let arr = [dateFactory.getDistanceToday(-30, false), dateFactory.getDistanceToday(0, false)]
      return arr;
    },
  },
  computed: {
    ...mapState(["hotelInfo"]),
     dateSplice() {
      return  this.StatisticsDate[1] ? this.StatisticsDate[0] + '~' + this.StatisticsDate[1] : this.StatisticsDate[0]
    }
  },
}
</script>

<style lang="scss" scoped>
.card-box {
  $gradient-blue: linear-gradient(90deg, #006fff, #189aff);
  $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
  $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
  $gradient-yellow: linear-gradient(
    90deg,
    rgb(255, 161, 50),
    rgb(255, 209, 50)
  );
  $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
  $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow,
    $gradient-red;
  .card-box-item {
    width: 20%;
    padding: 10px 20px;
    background-repeat: no-repeat;
    text-align: center;
    background-position: 0 0;
    background-size: 100% 100%;
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 6px;
    @each $c in $list-bg {
      $i: index($list-bg, $c);
      &:nth-child(#{$i}) {
        background: nth($list-bg, $i);
      }
    }
    .item-tit {
      text-align: left;
      font-size: 18px;
      font-weight: 400;
    }
    .item-date {
      text-align: left;
    }
    .item-text {
      margin:30px 0;
      font-size: 30px;
      font-weight: 700;
    }
    .item-rect {
      background: #f1f1f1;
      width: 10px;
      height: 18px;
      margin-right: 3px;
    }
    .item-rect-ac {
      background: #ff2332;
    }
  }
}

 .chart-box {
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      .chart-item {
        width: calc(50% - 10px);
        .myChart {
          width: 100%;
          height: 300px;
        }
      }
      .chart-item-bg {
        .myChart {
          width: 100%;
          height: 300px;
        }
      }
    }
</style>